import React, {useEffect, useState} from 'react';
import getStructuredData, {Props} from './utils/getStructuredData';

const LiveBlogStructuredData: React.FC<Props> = ({articleLinkHostName, topicArticles}) => {
  const [structuredData, setStructuredData] = useState(getStructuredData({articleLinkHostName, topicArticles}));

  useEffect(() => {
    setStructuredData(getStructuredData({articleLinkHostName, topicArticles}));
  }, [articleLinkHostName, topicArticles]);

  return <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}} />;
};

export default LiveBlogStructuredData;
