import {TopicArticle} from '../../App/App';

interface Props {
  articleLinkHostName: string;
  position: number;
  topicArticle: TopicArticle;
}

// according to this:
// https://stackoverflow.com/questions/53489943/schema-org-for-category-page-that-lists-articles
// a simple ItemList containing ListItems with just every item urls is recommended by Google
// if you are marking up a list of things that exist on other pages
// the full data structure should be added in the article page instead
const getElementStrucuredData = ({articleLinkHostName, position, topicArticle}: Props) => {
  return {
    '@type': 'ListItem',
    position,
    url: `${articleLinkHostName}${topicArticle.articleURL}`
  };
};

export default getElementStrucuredData;
