const waitForDocumentReady = async () =>
  new Promise<void>((resolve) => {
    if ((document.readyState === 'interactive' && Boolean(document.body)) || document.readyState === 'complete') {
      resolve();
    } else {
      document.addEventListener('DOMContentLoaded', () => resolve());
    }
  });

const documentReady = waitForDocumentReady();

export default documentReady;
