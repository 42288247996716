import React, {useEffect, useRef, useState} from 'react';

import {MAILPLUS_TOPIC} from '../../../server/utils/constants';
import delay from '../../utils/delay';
import {AppPropsAndState} from '../App/App';
import MobileArticles from '../mobile/Articles/Articles';
import DesktopArticles from '../desktop/Articles/Articles';

import {LOGGER_PREFIX} from '../../config';
import styles from './styles.css';
import {getArticles} from './utils/getArticles';

const getMailplusPageError = (error: unknown) =>
  `${LOGGER_PREFIX} ERROR fetching new articles in mailplus page. Error: ${error}`;

const getTopicPageError = (error: unknown) => `${LOGGER_PREFIX} ERROR fetching new articles. Error: ${error}`;

interface Props extends AppPropsAndState {
  layout: 'mobile' | 'desktop';
}

const InfiniteScroll: React.FC<Props> = ({
  articleLinkHostName,
  curPage,
  layout,
  setCurPage,
  setTopicArticles,
  size,
  skipArticleId,
  topic,
  topicArticles,
  totalPages
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingDivOnScreen, setIsLoadingDivOnScreen] = useState(false);
  const [isLoadingDivFirstTimeOnScreen, setIsLoadingDivFirstTimeOnScreen] = useState(false);
  const [isLoadingBtnActiveAfterDataLoadedDelay, setIsLoadingBtnActiveAfterDataLoadedDelay] = useState(true);
  const loadingRef = useRef<HTMLDivElement>(null);

  const Articles = layout === 'mobile' ? MobileArticles : DesktopArticles;

  useEffect(() => {
    if (!isLoadingBtnActiveAfterDataLoadedDelay) {
      const setLoadingBtnActiveAfterDelay = async (ms: number) => {
        await delay(ms);
        setIsLoadingBtnActiveAfterDataLoadedDelay(true);
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setLoadingBtnActiveAfterDelay(5000);
    }
  }, [isLoadingBtnActiveAfterDataLoadedDelay]);

  useEffect(() => {
    // limit the number of pages so that we don't waste crawl budget
    if (curPage >= totalPages) {
      setCurPage(totalPages);
      setHasMore(false);
      setIsLoading(false);

      return;
    }

    if (isLoadingBtnActiveAfterDataLoadedDelay && isLoadingDivFirstTimeOnScreen && !isLoading) {
      const newPage = Number(curPage) + 1;

      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);
        setIsLoadingBtnActiveAfterDataLoadedDelay(false);

        try {
          const newTopicArticles = await getArticles({
            page: newPage,
            size,
            skipArticleId,
            topic
          });

          if (newTopicArticles.length > 0) {
            setTopicArticles(topicArticles.concat(newTopicArticles));

            if (newTopicArticles.length < size) {
              setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
        } catch (error) {
          console.error(topic === MAILPLUS_TOPIC ? getMailplusPageError(error) : getTopicPageError(error));
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };

      setCurPage(newPage);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();

      setIsLoadingDivFirstTimeOnScreen(false);
    }
  }, [
    curPage,
    isLoading,
    isLoadingBtnActiveAfterDataLoadedDelay,
    isLoadingDivFirstTimeOnScreen,
    setCurPage,
    setIsLoadingDivFirstTimeOnScreen,
    setTopicArticles,
    size,
    skipArticleId,
    topic,
    topicArticles,
    totalPages
  ]);

  useEffect(() => {
    // here we handle what happens when user scrolls to Load More div
    // in this case we just update page variable
    const handleObserver: IntersectionObserverCallback = (entities) => {
      const target = entities[0];

      if (target.isIntersecting) {
        if (!isLoadingDivOnScreen) {
          setIsLoadingDivOnScreen(true);
          setIsLoadingDivFirstTimeOnScreen(true);
        }
      } else {
        if (isLoadingDivOnScreen) {
          setIsLoadingDivOnScreen(false);
          setIsLoadingDivFirstTimeOnScreen(false);
        }
      }
    };

    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(handleObserver);

    const curLoadingRef = loadingRef.current;

    if (curLoadingRef) {
      observer.observe(curLoadingRef);
    }

    return () => {
      if (curLoadingRef) {
        observer.unobserve(curLoadingRef);
      }
      observer.disconnect();
    };
  }, [isLoadingDivOnScreen, loadingRef, setIsLoadingDivOnScreen, setIsLoadingDivFirstTimeOnScreen]);

  if (isError) {
    return <Articles articles={topicArticles} articleLinkHostName={articleLinkHostName} />;
  }

  return (
    <div className={styles.infiniteScroll}>
      {<Articles articles={topicArticles} articleLinkHostName={articleLinkHostName} />}
      {hasMore && (
        <div ref={loadingRef}>
          <h4>Loading...</h4>
        </div>
      )}
    </div>
  );
};

export default InfiniteScroll;
