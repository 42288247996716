import React from 'react';
import classNames from 'classnames';
import {AppPropsAndState} from '../../App/App';

import Title from '../Title/Title';
import Pagination from '../Pagination/Pagination';
import MainArticle from '../MainArticle/MainArticle';
import Articles from '../Articles/Articles';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';

import styles from './styles.css';

const DesktopApp: React.FC<AppPropsAndState> = (props) => {
  return (
    <div className={classNames(styles.app, props.isPlatformCc ? styles.disableAllPointerEvents : '')}>
      <Title {...props} />

      {props.isPaginated && (
        <div className={styles.topPagination}>
          <Pagination {...props} />
        </div>
      )}

      <div className={styles.content}>
        <MainArticle {...props} />

        <Articles articles={props.topicArticles.slice(1)} articleLinkHostName={props.articleLinkHostName} />

        {props.isInfiniteScroll && <InfiniteScroll {...props} layout="desktop" />}
      </div>

      {props.isPaginated && (
        <div className={styles.bottomPagination}>
          <Pagination {...props} />
        </div>
      )}
    </div>
  );
};

export default DesktopApp;
