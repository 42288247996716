import renderAllInstances from './renderAllInstances';

const init = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('message', (event) => {
      if (event.data.scope !== 'cc') {
        return;
      }

      renderAllInstances(event.data.data);
    });
  }

  renderAllInstances();
};

export default init;
