import classNames from 'classnames';
import React from 'react';
import {MAILPLUS_TOPIC} from '../../../../server/utils/constants';
import {AppPropsAndState} from '../../App/App';

import styles from './styles.css';

const Title: React.FC<AppPropsAndState> = ({channel, topic}) => {
  const isMailplus = topic === MAILPLUS_TOPIC;
  const UPPER_CASE_TOPIC = topic.toUpperCase();

  const channelTopic = channel.topics.find(({topicName}) => topicName.toUpperCase() === UPPER_CASE_TOPIC);

  if (!channelTopic) {
    return null;
  }

  return <h2 className={classNames('wocc', styles.title, isMailplus && styles[topic])}>{channelTopic.previewText}</h2>;
};

export default Title;
