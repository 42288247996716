import {DMWindow, DM} from '../types';

import documentReady from './documentReady';

declare let window: DMWindow;
declare const IS_CC_BUILD: boolean;

const waitForGlobalReactReady = async () => {
  await documentReady;

  if (!window.DM || window.DM.molFeReact || IS_CC_BUILD) {
    return;
  }

  await new Promise<void>((resolve) => {
    (window.DM as DM).later('molFeReact_LOADED', resolve);
  });
};

export default waitForGlobalReactReady;