import React from 'react';
import classnames from 'classnames';
import {TopicArticle} from '../../App/App';
import pluralSuffix from '../../../utils/pluralSuffix';
import styles from './styles.css';

interface ShareBarMobileProps {
  article: TopicArticle;
}

const locationOrigin = typeof window === 'undefined' ? '' : window.location.origin;

const ShareBarMobile: React.FC<ShareBarMobileProps> = ({article}) => {
  const absoluteArticleURL = `${locationOrigin}${article.articleURL}`;

  return (
    <div
      className={classnames('social', 'home-preview', styles.shareBar)}
      data-location="topic-live"
      data-url={absoluteArticleURL}
    >
      <div className="comments-count">
        <a href={`${absoluteArticleURL}#comments-${article.articleId}`}>
          <em>{article.commentsCount}</em> comment{pluralSuffix(article.commentsCount)}
        </a>
      </div>
    </div>
  );
};

export default ShareBarMobile;
