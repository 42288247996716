interface Props {
  ico: string;
  url: string;
}

const addIcoToUrl = ({ico, url}: Props) => {
  const separator = url.includes('?') ? '&' : '?';

  return `${url}${separator}ico=${ico}`;
};

export default addIcoToUrl;
