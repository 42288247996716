import {getMolFeFeedsUrl} from '../../shared/utils/getMolFeFeedsUrl';

export interface GetTopicArticlesAPIUrl {
  page: number;
  size: number;
  skipArticleId: null | string;
  topic: string;
}

const getTopicArticlesAPIUrl = ({page, size, skipArticleId, topic}: GetTopicArticlesAPIUrl) =>
  `${getMolFeFeedsUrl()}/articles/topicArticles/topic/${encodeURI(topic.trim())}/${
    (skipArticleId && `skipArticle/${skipArticleId}/`) || ''
  }page/${page}/size/${size}`;

export default getTopicArticlesAPIUrl;
