import React from 'react';
import classNames from 'classnames';
import {AppProps} from '../../App/App';
import formatFirstPubDate from '../../../utils/formatFirstPubDate';
import ShareBarDesktop, {ShareBarDesktopProps} from '../ShareBarDesktop/ShareBarDesktop';

import addIcoToUrl from '../../../utils/addIcoToUrl';
import styles from './styles.css';

const MainArticle: React.FC<AppProps> = ({articleLinkHostName, topicArticles}) => {
  const mainArticle = topicArticles[0];

  if (!mainArticle) {
    return null;
  }

  const {articleURL, firstPubDate, headline, images, largePreviewText, isPaywalled} = mainArticle;

  const link = addIcoToUrl({
    ico: 'topics_pagination_desktop',
    url: `${articleLinkHostName}${articleURL}`
  });

  const defaultImg = images.find(({imageType}) => imageType === 'ARTICLE_PREVIEW_LARGE');

  const shareBarProps: ShareBarDesktopProps = {
    article: mainArticle
  };

  return (
    <div className={classNames(styles.mainArticle, 'article')}>
      <h2 className="linkro-darkred">
        {isPaywalled && <span className="is-paywalled" />}
        <a href={link}>{headline}</a>
      </h2>

      <div className={styles.container}>
        <a className={styles.leftCol} href={link}>
          <img src={defaultImg?.hostUrl} alt={defaultImg?.altTag || undefined} />
        </a>

        <div className={styles.rightCol}>
          {firstPubDate && <div className={styles.firstPubDate}>{formatFirstPubDate(firstPubDate)}</div>}

          <div className={classNames(styles.previewText, 'link-ccow')}>
            {largePreviewText}
            <a href={link}>...read</a>
          </div>

          <ShareBarDesktop {...shareBarProps} />
        </div>
      </div>
    </div>
  );
};

export default MainArticle;
