/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

export interface ClientLogger {
  error: (...args: any[]) => void;
  info: (...args: any[]) => void;
  log: (...args: any[]) => void;
  warn: (...args: any[]) => void;
}

const logger: ClientLogger = {
  error: console.error,
  info: console.info,
  log: console.log,
  warn: console.warn
};

export const getClientLogger = (): ClientLogger => logger;
