import React from 'react';
import ReactPaginate from 'react-paginate';
import removeQueryStringParamsAndHashes from '../../../utils/removeQueryStringParamsAndHashes';
import {AppProps} from '../../App/App';
import styles from './styles.css';

interface SelectedItem {
  selected: number;
}

const PaginationMobile: React.FC<AppProps> = ({envUrl, page, totalPages}) => {
  const curPageUrl = removeQueryStringParamsAndHashes(envUrl);

  // ensure page 1 does not have 'page 1' and is always just the baseline URL
  const hrefBuilder = (pageIndex: number) => (pageIndex === 1 ? curPageUrl : `${curPageUrl}?page=${pageIndex}`);

  const handleOnPageChange = ({selected}: SelectedItem) => {
    if (typeof window !== 'undefined') {
      window.location.href = hrefBuilder(selected + 1);
    }
  };

  return (
    <div className={styles.pagination}>
      <div>
        Page {page} of {totalPages}
      </div>
      <ReactPaginate
        disableInitialCallback={true}
        hrefBuilder={hrefBuilder}
        initialPage={page - 1}
        marginPagesDisplayed={0}
        nextLabel={'Next >'}
        onPageChange={handleOnPageChange}
        pageCount={totalPages}
        pageRangeDisplayed={0}
        previousLabel={'< Prev'}
      />
    </div>
  );
};

export default PaginationMobile;
