import {TopicArticleChannel} from '../../shared/components/App/App';

const DEFAULT_PAGE = 1;
const HIGHEST_PAGE = 20; // limit the number of pages so that we don't waste crawl budget

const ITEMS_PER_PAGE_IN_PAGINATED_MODE = 80;
const ITEMS_PER_PAGE_IN_INFINITE_SCROLL_MODE = 60;

const TIMEOUT_IN_MILLISECONDS = 6000;

const MAILPLUS_TOPIC = 'mailplus';

const mailplusChannel: TopicArticleChannel = {
  channelId: 1,
  h1DisplayName: 'MAIL+ LATEST NEWS, PHOTOS AND VIDEO',
  topics: [
    {
      previewText: 'MAIL+ LATEST NEWS, PHOTOS AND VIDEO',
      topicName: 'mailplus'
    }
  ]
};

export {
  DEFAULT_PAGE,
  HIGHEST_PAGE,
  ITEMS_PER_PAGE_IN_PAGINATED_MODE,
  ITEMS_PER_PAGE_IN_INFINITE_SCROLL_MODE,
  TIMEOUT_IN_MILLISECONDS,
  MAILPLUS_TOPIC,
  mailplusChannel
};
