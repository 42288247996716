import {TopicArticle} from '../../App/App';
import getElementStrucuredData from './getElementStrucuredData';

export interface Props {
  articleLinkHostName: string;
  topicArticles: TopicArticle[];
}

const getStructuredData = ({articleLinkHostName, topicArticles}: Props) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: topicArticles.map((topicArticle, index) => {
      return getElementStrucuredData({
        articleLinkHostName,
        position: index + 1,
        topicArticle
      });
    })
  };
};

export default getStructuredData;
