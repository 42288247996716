import React from 'react';
import {TopicArticle} from '../../App/App';
import Article from '../Article/Article';

import styles from './styles.css';

interface ArticlesProps {
  articles: (TopicArticle | undefined)[];
  articleLinkHostName: string;
}

const Articles: React.FC<ArticlesProps> = ({articles, articleLinkHostName}) => {
  if (articles.length === 0) {
    return null;
  }

  return (
    <ul className={styles.articles}>
      {articles.map((article, index) => {
        return article && <Article article={article} key={index} articleLinkHostName={articleLinkHostName} />;
      })}
    </ul>
  );
};

export default Articles;
