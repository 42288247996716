import waitForGlobalReactReady from './utils/waitForGlobalReactReady';
import init from './utils/init';
import {DMWindow} from './types';

declare let window: DMWindow;
declare const IS_CC_BUILD: boolean;

const initWhenGlobalReactIsready = async () => {
  await waitForGlobalReactReady();

  init();

  if (IS_CC_BUILD) {
    window.postMessage({name: 'XpModuleLoaded'}, '*');
  }
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
initWhenGlobalReactIsready();
