import React from 'react';
import classNames from 'classnames';

import {MAILPLUS_TOPIC} from '../../../../server/utils/constants';
import {TopicArticleChannel} from '../../App/App';

import styles from './styles.css';

interface TitleProps {
  channel: TopicArticleChannel;
  topic: string;
}

const Title: React.FC<TitleProps> = ({channel, topic}) => {
  if (!channel.h1DisplayName) {
    return null;
  }

  const isMailplus = topic === MAILPLUS_TOPIC;

  return <h1 className={classNames(styles.title, isMailplus && styles[topic])}>{channel.h1DisplayName}</h1>;
};

export default Title;
