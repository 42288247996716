import {XpModule} from '@mol-fe/mol-fe-registry-types';

declare const xpModule: XpModule;

export type Environment = 'test' | 'development' | 'integration' | 'production';

export const environment: Environment = (typeof xpModule !== 'undefined' && xpModule.environment) || 'development';

const xpModuleVersion = (typeof xpModule !== 'undefined' && xpModule.version) || undefined;

const USE_LOCAL_MOL_FE_FEEDS = false;

const config = {
  development: {
    // similar to "http://localhost:8080/dist" but this way it works fine also
    // when consuming this xpModule from a virtualbox image using ngrok
    // because it's a relative path instead of an absolute one
    baseUrl: 'dist',
    molFeFeeds: {
      clientSide: {
        url: USE_LOCAL_MOL_FE_FEEDS ? 'http://localhost:8302/public' : 'https://www.dailymailint.co.uk/api/mol-fe-feeds'
      },
      serverSide: {
        url: USE_LOCAL_MOL_FE_FEEDS ? 'http://localhost:8302/public' : 'https://www.dailymailint.co.uk/api/mol-fe-feeds'
      },
      version: 'v2'
    }
  },
  integration: {
    baseUrl: `https://scripts.dailymail.co.uk/static/mol-fe/static/mol-fe-xpmodule-topic-page/${xpModuleVersion}`,
    molFeFeeds: {
      clientSide: {
        url: 'https://www.dailymailint.co.uk/api/mol-fe-feeds'
      },
      serverSide: {
        url: 'http://varnish-vip/api/mol-fe-feeds'
      },
      version: 'v2'
    }
  },
  production: {
    baseUrl: `https://scripts.dailymail.co.uk/static/mol-fe/static/mol-fe-xpmodule-topic-page/${xpModuleVersion}`,
    molFeFeeds: {
      clientSide: {
        url: 'https://www.dailymail.co.uk/api/mol-fe-feeds'
      },
      serverSide: {
        url: 'http://varnish-vip/api/mol-fe-feeds'
      },
      version: 'v2'
    }
  },
  test: {
    baseUrl: 'baseUrl',
    molFeFeeds: {
      clientSide: {
        url: 'fromClientSideMolFeFeedsUrl'
      },
      serverSide: {
        url: 'fromServerSideMolFeFeedsUrl'
      },
      version: 'molFeFeedsVersion'
    }
  }
};

export const baseUrl = config[environment].baseUrl;

export const clientSideMolFeFeedsUrl = `${config[environment].molFeFeeds.clientSide.url}/${config[environment].molFeFeeds.version}`;

export const serverSideMolFeFeedsUrl = `${config[environment].molFeFeeds.serverSide.url}/${config[environment].molFeFeeds.version}`;

export const LOGGER_PREFIX = '[mol-fe-xpmodule-topic-page]';
