import axios from 'axios';

import {TopicArticle} from '../components/App/App';
import {getMolFeFeedsUrl} from './getMolFeFeedsUrl';

export type GetPaywalledArticles = {
  page: number;
  size: number;
};

type GetPaywalledArticlesResponse = {
  articles: TopicArticle[];
  page: number;
  size: number;
  total: number;
  totalPages: number;
};

export const getPaywalledArticles = async ({
  page,
  size
}: GetPaywalledArticles): Promise<GetPaywalledArticlesResponse> => {
  const molFeFeedsUrl = getMolFeFeedsUrl();
  const response = await axios.get(`${molFeFeedsUrl}/articles/paywalled/page/${page}/size/${size}`);

  return response.data;
};
