import url from 'url';
import React from 'react';
import {render, hydrate} from 'react-dom';

import App, {AppProps} from '../../shared/components/App/App';
import {LOGGER_PREFIX} from '../../shared/config';

declare const IS_CC_BUILD: boolean;

const renderAllInstances = (propsFromCC?: AppProps) =>
  Array.from(document.querySelectorAll('[data-topic-page]')).forEach((element: Element) => {
    const propsFromHtmlElementData = element.getAttribute('data-topic-page');

    let props = (propsFromHtmlElementData && (JSON.parse(propsFromHtmlElementData) as AppProps)) || undefined;

    if (props) {
      if (IS_CC_BUILD) {
        if (typeof propsFromCC !== 'undefined') {
          props = propsFromCC;

          // eslint-disable-next-line no-console
          console.log('[data-topic-page] new props from CCint: ', props);
        }

        // eslint-disable-next-line no-console
        console.log('[data-topic-page] final props: ', props);
      }

      const parsedUrl = url.parse(window.location.href, true);
      const pageInUrl = Number(parsedUrl.query.page) || undefined;

      // ensure page 1 does not have 'page 1' and is always just the baseline URL
      if (pageInUrl === 1) {
        parsedUrl.search = null;
        delete parsedUrl.query.page;

        window.location.href = url.format(parsedUrl);
      } else if (typeof pageInUrl !== 'undefined' && pageInUrl > props.totalPages) {
        parsedUrl.search = null;
        parsedUrl.query.page = String(props.totalPages);

        window.location.href = url.format(parsedUrl);
      } else {
        hydrate(<App {...props} />, element);
      }
    } else {
      console.error(`${LOGGER_PREFIX} renderAllInstances. falsy propsFromHtmlElementData. element: `, element);
      if (IS_CC_BUILD) {
        render(<div>{LOGGER_PREFIX} Undefined props. Look at the console for more details.</div>, element);
      } else {
        render(<div />, element);
      }
    }
  });

export default renderAllInstances;
