import axios from 'axios';

import getTopicArticlesAPIUrl, {GetTopicArticlesAPIUrl} from '../../../../server/utils/getTopicArticlesAPIUrl';
import {getPaywalledArticles} from '../../../utils/getPaywalledArticles';
import {MAILPLUS_TOPIC} from '../../../../server/utils/constants';
import {TopicArticle} from '../../App/App';
import {LOGGER_PREFIX} from '../../../config';
import {getLogger} from '../../../utils/getLogger';

const getTopicArticles = async ({
  page,
  size,
  skipArticleId,
  topic
}: GetTopicArticlesAPIUrl): Promise<TopicArticle[]> => {
  const url = getTopicArticlesAPIUrl({page, size, skipArticleId, topic});

  try {
    const response = await axios(url);

    return response.data.topicArticles;
  } catch (error) {
    getLogger().error(`${LOGGER_PREFIX} error retrieving topic articles from "${url}"`, {
      error,
      functionName: 'getTopicArticles',
      functionProps: {
        page,
        size,
        skipArticleId,
        topic
      }
    });

    throw error; // Re-throw the error to ensure it's not silently swallowed
  }
};

export const getArticles = async ({
  topic,
  page,
  size,
  skipArticleId
}: GetTopicArticlesAPIUrl): Promise<TopicArticle[]> => {
  let articles: TopicArticle[] = [];

  if (topic === MAILPLUS_TOPIC) {
    articles = (await getPaywalledArticles({page, size})).articles;
  } else {
    articles = await getTopicArticles({page, size, skipArticleId, topic});
  }

  return articles;
};
