import React from 'react';
import classnames from 'classnames';
import {TopicArticle} from '../../App/App';
import pluralSuffix from '../../../utils/pluralSuffix';

import styles from './styles.css';

export interface ShareBarDesktopProps {
  article: TopicArticle;
}

const ShareBarDesktop: React.FC<ShareBarDesktopProps> = ({article}) => {
  const articleURL = article.articleURL;
  const shareCount = Number(article.fbShareCount || 0) + Number(article.piShareCount || 0);
  const imageLargeDouble = article.images.find(({imageType}) => imageType === 'ARTICLE LARGE DOUBLE')?.hostUrl || '';

  return (
    <div
      className={classnames('article-icon-links-container', styles.shareBar, styles.desktop)}
      data-followbutton="MailOnline"
    >
      <ul className="article-icon-links cleared">
        <li className="first">
          <a className="comments-link" href={`${articleURL}?ico=livefeed#comments`} rel="nofollow">
            <span className="icon" />
            <span className="linktext">
              <span className="readerCommentNo bold">{article.commentsCount || ''}</span> comment
              {pluralSuffix(article.commentsCount)}
            </span>
          </a>
        </li>

        {Boolean(article.videoCount) && (
          <li>
            <a className="videos-link" href={`${articleURL}?ico=livefeed#video`} rel="nofollow">
              <span className="icon" />
              <span className="linktext">
                <span className="bold">{article.videoCount}</span> video{pluralSuffix(article.videoCount)}
              </span>
            </a>
          </li>
        )}

        <li>
          <a
            className="share-link"
            data-social-drawer="true"
            data-url={articleURL}
            data-email-share-style="email_share_article-drawer"
            data-headline={article.headline}
            data-article-image={imageLargeDouble}
            data-show-flipboard="true"
            data-show-fbmessenger="true"
            href="#"
          >
            <span className="icon" />
            <span className="linktext">
              <span className="bold">{shareCount || ''}</span> share{pluralSuffix(shareCount)}
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ShareBarDesktop;
